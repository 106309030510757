<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/property_payments', route: '/admin/processes/payments', name: 'property_payment' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'Payment'
}
</script>
